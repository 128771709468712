import React from 'react';
import { Helmet } from 'react-helmet';

// Wraps every page in a component
export const wrapPageElement = ({ element }) => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en-GB',
        }}
      >
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <meta
          name="description"
          content="Maximize your online impact and establish a powerful brand identity with Speakerbox the digital marketing, branding, and web design agency. Our expert team crafts captivating websites, implements strategic marketing campaigns, and delivers exceptional branding solutions to your business goals."
        />
        <meta
          property="og:description"
          content="Maximize your online impact and establish a powerful brand identity with Speakerbox the digital marketing, branding, and web design agency. Our expert team crafts captivating websites, implements strategic marketing campaigns, and delivers exceptional branding solutions to your business goals."
        />
      </Helmet>
      {element}
    </>
  );
};
