exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/About_us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-be-a-client-js": () => import("./../../../src/pages/BeAClient.js" /* webpackChunkName: "component---src-pages-be-a-client-js" */),
  "component---src-pages-blog-[id]-jsx": () => import("./../../../src/pages/blog/[id].jsx" /* webpackChunkName: "component---src-pages-blog-[id]-jsx" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-branding-js": () => import("./../../../src/pages/branding.js" /* webpackChunkName: "component---src-pages-branding-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/caseStudies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-gatsby-js": () => import("./../../../src/pages/gatsby.js" /* webpackChunkName: "component---src-pages-gatsby-js" */),
  "component---src-pages-gatsby-showcase-js": () => import("./../../../src/pages/gatsbyShowcase.js" /* webpackChunkName: "component---src-pages-gatsby-showcase-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paid-media-js": () => import("./../../../src/pages/paidMedia.js" /* webpackChunkName: "component---src-pages-paid-media-js" */),
  "component---src-pages-services-brand-identity-development-jsx": () => import("./../../../src/pages/services/Brand_Identity_Development.jsx" /* webpackChunkName: "component---src-pages-services-brand-identity-development-jsx" */),
  "component---src-pages-services-digital-solutions-ecommerce-jsx": () => import("./../../../src/pages/services/Digital_solutions_Ecommerce.jsx" /* webpackChunkName: "component---src-pages-services-digital-solutions-ecommerce-jsx" */),
  "component---src-pages-services-paid-media-campaigns-jsx": () => import("./../../../src/pages/services/Paid_Media_Campaigns.jsx" /* webpackChunkName: "component---src-pages-services-paid-media-campaigns-jsx" */),
  "component---src-pages-services-search-engine-optimization-jsx": () => import("./../../../src/pages/services/Search_Engine_Optimization.jsx" /* webpackChunkName: "component---src-pages-services-search-engine-optimization-jsx" */),
  "component---src-pages-services-social-media-management-jsx": () => import("./../../../src/pages/services/Social_Media_Management.jsx" /* webpackChunkName: "component---src-pages-services-social-media-management-jsx" */),
  "component---src-pages-services-strategy-brand-positioning-jsx": () => import("./../../../src/pages/services/Strategy_brand_positioning.jsx" /* webpackChunkName: "component---src-pages-services-strategy-brand-positioning-jsx" */),
  "component---src-pages-services-ui-ux-design-jsx": () => import("./../../../src/pages/services/UI_UX_Design.jsx" /* webpackChunkName: "component---src-pages-services-ui-ux-design-jsx" */),
  "component---src-pages-services-video-ad-jsx": () => import("./../../../src/pages/services/Video_ad.jsx" /* webpackChunkName: "component---src-pages-services-video-ad-jsx" */),
  "component---src-pages-services-web-and-seo-js": () => import("./../../../src/pages/services/webAndSeo.js" /* webpackChunkName: "component---src-pages-services-web-and-seo-js" */),
  "component---src-pages-services-web-design-and-development-jsx": () => import("./../../../src/pages/services/Web_Design_and_Development.jsx" /* webpackChunkName: "component---src-pages-services-web-design-and-development-jsx" */),
  "component---src-pages-social-media-marketing-js": () => import("./../../../src/pages/socialMediaMarketing.js" /* webpackChunkName: "component---src-pages-social-media-marketing-js" */),
  "component---src-pages-strategy-and-brand-positioning-js": () => import("./../../../src/pages/StrategyAndBrandPositioning.js" /* webpackChunkName: "component---src-pages-strategy-and-brand-positioning-js" */),
  "component---src-pages-supermarkets-and-department-stores-jsx": () => import("./../../../src/pages/supermarketsAndDepartmentStores.jsx" /* webpackChunkName: "component---src-pages-supermarkets-and-department-stores-jsx" */),
  "component---src-templates-case-studies-js": () => import("./../../../src/templates/caseStudies.js" /* webpackChunkName: "component---src-templates-case-studies-js" */)
}

